
















































































































































































































































.panel-report {}

.report-year {
  position: relative;
  height: 100%;

  /*
  .eh-title {
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.86);
    border-radius: 10px;
    padding: 12px 15px 0px 15px;

    .ft {
      position: relative;
      font-size: 18px;
      font-weight: bold;
      color: #01B0B0;
      padding-left: 7px;
      top: 7px;
    }
  }
 */
  /*::v-deep .el-tabs {
    margin-top: 0 !important;
    padding-top: 8px !important;

    box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.86);

    .el-tabs__nav-next,
    .el-tabs__nav-prev {
      line-height: 30px;
    }

    .el-tabs__header {
      margin: 0 10px !important;

      .el-tabs__nav-wrap {
        &::after {
          display: none;
        }
      }
    }

    .el-tabs__item {
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
  }*/

  /*.eh-chart-content {
    padding: 0px 24px 0px 0px;
    width: 100%;
    height: 280px;
    box-sizing: border-box;
  }*/
}

/*.eh-chart-content {
  padding: 0px 24px 0px 0px;
  width: 100%;
  height: 280px;
  box-sizing: border-box;
}*/
.chart-year {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.chart-year__legend {
  position: relative;
  flex: none;
  /*margin: 12px 0 0;*/
  padding-right: 15px;
  font-size: 12px;
  color: #333;
  text-align: right;
  padding-top: 12px;
  .pos_left{
    position: absolute;
    left: 37px;
    top: 10px;
  }
  .item {
    display: inline-flex;
    align-items: center;
    margin-left: 12px;

    &:last-child {
      margin-left: 26px;
    }

    .icon-circle {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      background: #7E80F8;
      border-radius: 50%;
      vertical-align: middle;

      &.icon-circle-cur {
        background: #27C0BB;
      }
    }
  }

}

.chart-year__container {
  flex: 1;
}

