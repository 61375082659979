










































































































































.deal-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 0;
  /*height: 100%;*/
}

.deal-top {
  position: relative;
  flex: none;
  display: flex;
  /*height: 110px;*/
  // min-height: 14%;
  padding: 0px 1.3vw;

  .mar-l-22 {
    margin-left: 1.15vw;
  }

  .top-one {
    flex: 1;
    display: flex;
    width: 0;
    /*flex-wrap: wrap;*/
    align-items: center;
    /*justify-content: center;*/
    /*width: 264px;*/
    /*height: 110px;*/
    background: #fff;
    box-shadow: 0px 6px 20px 0px rgb(235 235 235 / 86%);
    border-radius: 10px;
    /*padding: 12px 10px 11px 15px;*/
    /*padding: 2.1% 0 2% 2.7%;*/
    padding: 2.26% 2.83%;
    box-sizing: border-box;

    img {
      width: 37%;
      max-width: 87px;
      /*vertical-align: bottom !important;*/
    }

    .one-cn {
      flex: 1;
      display: inline-block;
      min-width: 102px;
      margin-left: 5%;
      position: relative;
      /*height: 100%;*/
      /*padding: 13px 0 0 0;*/
      box-sizing: border-box;

      p:nth-child(1) {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }

      p:nth-child(2) {
        // font-size: 30px;
        font-size: 1.5625vw;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
        margin-top: 11.85%;
      }
    }

  }

  @media screen and (max-width:1300px) {
    .top-one {
      flex-direction: column;
      justify-content: center;

      .one-cn {
        margin: 10% 0 0;
        text-align: center;

      }
    }
  }
}

.deal-center {
  position: relative;
  flex: 1;
  height: 0;
  display: flex;
  margin: 3% 0 9.6%;
  align-items: center;
  justify-content: center;
  /*overflow-y: hidden;*/

  .bg {
    height: 100%;
    -webkit-animation: aniDealBg 2s ease-in infinite;
    -o-animation: aniDealBg 2s ease-in infinite;
    animation: aniDealBg 2s ease-in infinite;
  }

  .amount {

    position: absolute;
    left: 0;
    top: 25%;
    width: 100%;
    text-align: center;
    font-weight: bold;

    .amount__label {
      color: #666;
      // font-size: 30px;
      font-size: 1.5625vw;
    }

    .amount__num {
      margin-top: 18px;
      // font-size: 60px;
      // font-size: 3.125vw;
      font-size: 1.875vw;
      font-weight: bold;
      color: #19B0B0;
      .num {
        margin-right: 6px;
        font-size: 3.125vw;
        vertical-align: -0.2vw;
      }
    }
  }

}

@keyframes aniDealLine {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  70% {
    opacity: .7;
    transform: translate3d(0, -10%, 0);
  }

  /*60% {
    opacity: 1;
    transform: translate3d(0,10%,0);
  }
  70% {
    opacity: 1;
    transform: translate3d(0,-10%,0);
  }*/
  100% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes aniDealBg {
  0% {
    opacity: .5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: .5;
  }
}

@keyframes aniDealCircleLine {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  70% {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }

  100% {
    opacity: 0;
    transform: translate3d(0, -30%, 0);
  }
}

@keyframes aniDealCircle {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  20% {
    opacity: .2;
    transform: translate3d(0, -120%, 0);
  }

  40% {
    opacity: .4;

    transform: translate3d(0, -240%, 0);
  }

  60% {
    opacity: .6;

    transform: translate3d(0, -360%, 0);
  }

  80% {
    opacity: .8;
    transform: translate3d(0, -480%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, -600%, 0);
  }
}

.deal-animation {
  position: relative;
  height: 100%;

  .line {
    position: absolute;
    left: 69%;
    bottom: 28%;
    display: inline-block;
    /*width: 4px;*/
    /*height: 81px;*/

    width: .52%;
    aspect-ratio: 1/20.5;
    border-radius: 10%;
    /*background: linear-gradient(to bottom,#16c6e1,rgba(255,255,255,0));*/
    -webkit-animation: aniDealLine .8s linear infinite;
    -o-animation: aniDealLine .8s linear infinite;
    animation: aniDealLine .8s linear infinite;

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      content: '';
      box-sizing: border-box;
      background: url(~@/assets/images/report/line-blue.png) no-repeat center top /100% 100%;

    }

    &.line2 {
      width: .9%;
      aspect-ratio: 1/13.5;
      left: 29%;
      bottom: 47%;
      animation-delay: .5s;

      &:before {
        opacity: .2;
      }

    }

    &.line3 {
      width: 0.6%;
      aspect-ratio: 1/25.25;
      left: 29.3%;
      bottom: 23%;
      animation-delay: .5s;

      &:before {
        opacity: 0.4;
      }

    }

    &.line4 {
      width: 0.45%;
      aspect-ratio: 1/33.33;
      left: 34%;
      bottom: 32%;
      animation-delay: .8s;

      &:before {
        background: url(~@/assets/images/report/line-yellow.png) no-repeat center top /100% 100%;
      }
    }

    &.line5 {
      width: 0.3%;
      aspect-ratio: 1/27.5;
      left: 48.6%;
      bottom: 25%;
      animation-delay: .6s;

      &:before {
        opacity: .2;
      }
    }

    &.line6 {
      width: 0.9%;
      aspect-ratio: 1/13.5;
      left: 64.6%;
      bottom: 25%;
      animation-delay: .7s;

      &:before {
        opacity: .2;
      }
    }

    &.line7 {
      width: 0.45%;
      aspect-ratio: 1/30;
      left: 63%;
      bottom: 40%;
      animation-delay: .2s;

      &:before {
        opacity: .2;
      }
    }

    &.line8 {
      width: 0.45%;
      aspect-ratio: 1/31;
      left: 76%;
      bottom: 41%;
      animation-delay: .9s;

      &:before {
        opacity: .2;
      }
    }

    &.line9 {
      width: 0.3%;
      aspect-ratio: 1/60;
      left: 47.5%;
      bottom: 45%;
      animation-delay: .4s;

      &:before {
        opacity: .2;
      }
    }

    &.line10 {
      width: 0.3%;
      aspect-ratio: 1/45;
      left: 42.5%;
      bottom: 48%;
      animation-delay: .1s;

      &:before {
        opacity: .2;
        background: url(~@/assets/images/report/line-yellow.png) no-repeat center top /100% 100%;
      }
    }

    &.line11 {
      width: 0.3%;
      aspect-ratio: 1/45;
      left: 42.5%;
      bottom: 48%;
      animation-delay: .6s;

      &:before {
        opacity: .2;
        background: url(~@/assets/images/report/line-yellow.png) no-repeat center top /100% 100%;
      }
    }

    &.line12 {
      width: 0.6%;
      aspect-ratio: 1/45;
      left: 54.5%;
      bottom: 32%;

      animation-delay: .5s;

      &:before {
        opacity: .2;
        background: url(~@/assets/images/report/line-yellow.png) no-repeat center top /100% 100%;
      }
    }

    &.line13 {
      width: 0.15%;
      aspect-ratio: 1/77;
      left: 23%;
      bottom: 38%;
      background: none;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.2s;

      &:before {
        border-right: 2px dashed #f1b517;
        opacity: .2;
      }
    }

    &.line14 {
      width: 0.15%;
      aspect-ratio: 1/77;
      left: 72%;
      bottom: 35%;
      background: none;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.1s;

      &:before {
        opacity: 0.4;
        border-right: 2px dashed #16c6e1;
      }
    }

    &.line15 {
      width: 0.15%;
      aspect-ratio: 1/77;
      left: 33%;
      bottom: 42%;
      background: none;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.4s;

      &:before {
        opacity: .2;
        border-right: 2px dashed #16c6e1;
      }
    }

    &.line16 {
      width: 0.15%;
      aspect-ratio: 1/77;
      left: 50%;
      left: 55%;
      bottom: 34%;
      background: none;
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.3s;

      &:before {
        opacity: .2;
        border-right: 2px dashed #16c6e1;
      }
    }
  }

  .line-circle {
    position: absolute;
    width: 6.15%;
    left: 5%;
    aspect-ratio: 1/3.58;
    bottom: 55%;
    background: url(~@/assets/images/report/line1.png) no-repeat center top /100% 100%;
    -webkit-animation: aniDealCircleLine 2s linear infinite;
    -o-animation: aniDealCircleLine 2s linear infinite;
    animation: aniDealCircleLine 2s linear infinite;
  }

  .circle {
    position: absolute;
    width: 15px;
    height: 15px;
    background: #71dced;
    border-radius: 100%;
    left: 83%;
    bottom: 74%;
    opacity: 0;
    -webkit-animation: aniDealCircle 1s linear 2s infinite;
    -o-animation: aniDealCircle 1s linear 2s infinite;
    animation: aniDealCircle 1s linear 2s infinite;
  }
}

.deal-bottom {
  flex: none;
  display: flex;
  align-items: center;
  height: 20.8%;
  // min-height: 128px;
  /*padding: 40px 29px 0px 15px;*/
  position: relative;
  margin: 0 1.3vw;
  padding: 3.6% 0 0 9.6%;
  box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);
  /*opacity: 0.6;*/
  border-radius: 10px;
  background: rgba(255, 255, 255, .6) url(~@/assets/images/report/bg-sku.png) no-repeat left top /auto 100%;

  .deal-bottom__inner {
    display: block;
    width: 100%;
    margin-top: -40px;
  }

  /*img {
    width: 100%;
    height: 100%;
  }*/
  .title {
    color: #999;
    font-size: 20px;
    font-size: 1.04vw;
  }

  .num {
    margin: 4% 0 0;
    line-height: 1em;
    font-size: 3.125vw;
    font-weight: bold;
    color: #666666;
  }

  .icon {
    position: absolute;
    width: 18%;
    max-width: 100px;
    right: 10%;
    top: 50%;
    transform: translate3d(0, -50%, 0);
  }

  /*p {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 60px;
    font-weight: bold;
    color: #666666;
    line-height: 31px;
    display: flex;
    align-items: center;
    padding-left: 14%;
  }*/
}

