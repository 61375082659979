

































































































































































































































.eh-title {
  .el-tabs__nav-wrap {
    box-shadow: none !important;
  }

  .el-tabs__nav-wrap::after {
    display: none !important;
  }

  .el-tabs__item {
    padding: 0 32px !important;
  }
}

.eh-content__list {
  .el-progress-bar__inner::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 2px solid #28CCCC;
    border-radius: 50%;
    top: 0px;
    right: 0px;
    box-sizing: border-box;
  }

  .el-progress-bar__inner {
    min-width: 20px;
    background: linear-gradient(90deg, #7E80F8 0%, #53D5E0 99%);
  }

  .el-progress-bar__outer {
    background-color: #ffffff;
  }

  .el-progress-bar__innerText {
    color: black !important;
  }
}
