











































.reportCenter {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 63px);
  padding-top: 2.44% !important;
  padding-bottom: 1.62% !important;

  .reportCenter__colLeft {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    /*max-width: 530px;*/
    width: 32%;
    /*min-width: 330px;*/
    border-radius: 5px;
    z-index: 1;
  }

  .reportCenter__colcenter {
    flex: none;
    /*max-width: 600px;*/
    width: 36%;
    display: flex;
    flex-direction: column;
    /*border-radius: 5px;*/
  }

  .reportCenter__colRight {
    position: relative;
    flex: none;
    display: flex;
    flex-direction: column;
    /*max-width: 530px;*/
    width: 32%;
    border-radius: 5px;
    z-index: 1;
    .panel {
      height: 0;
    }
  }

}

