

























































































































































































































































.eh-content {
  position: relative;
  height: 100%;

  .eh-title {
    padding: 12px 0px 0px 15px;

    .ft {
      position: relative;
      font-size: 18px;
      font-weight: bold;
      color: #01B0B0;
      padding-left: 7px;
      /*top: 7px;*/
    }
  }

  .eh-chart-content {
    /*padding: 0px 24px 35px 0px;*/
    width: 100%;
    height: 320px;
    box-sizing: border-box;
  }
}

.chart-product {
  min-width: 100%;
  overflow: hidden;
}

.legend-year {
  /*position: absolute;*/
  position: relative;
  margin: 14px 0 0;
  right: 0;
  padding-right: 15px;
  font-size: 12px;
  color: #333;
  text-align: right;

  .pos_left {
    position: absolute;
    left: 37px;
    top: 10px;
  }

  .item {
    display: inline-flex;
    align-items: center;
    margin-left: 12px;

    &:last-child {
      margin-left: 26px;
    }

    .icon-circle {
      display: inline-block;
      vertical-align: middle;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      background: #7E80F8;
      border-radius: 50%;
      vertical-align: middle;

      &.icon-circle-cur {
        background: #27C0BB;
      }
    }
  }

}

