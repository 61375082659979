














































































































.panel {
  flex: 1;
  display: flex;
  /*overflow: hidden;*/
}

.sku-content {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /*width: 255px;*/
  width: 0;
  height: 100%;
  /*height: 374px;*/

  /* .sku-img {
      position: absolute;
      top: -5px;
      left: -7px;
      width: 100%;
      height: 100%;
    } */
  &:first-child {
    z-index: 2;
  }
}

.sku-title {
  width: 100%;
  flex: none;
  position: relative;
  z-index: 1;
  color: #01B0B0;
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;

  .sku-title__inner {
    position: relative;
    display: inline-block;
    max-width: calc(100% - 48px);
    height: 28px;
    padding: 12px 10px 0px 15px;
    box-sizing: border-box;
    vertical-align: top;
    border-radius: 10px 0 0 0;
    background: #fff;
    z-index: 1;

    .icon {
      width: 1.8vw;
      max-width: 35px;
      margin-right: 6px;
    }

  }

  &::after {
    /*position: absolute;*/
    position: relative;
    display: inline-block;
    width: 24px;
    /*height: 127%;*/
    height: 30px;
    vertical-align: top;
    /*top: -7px;*/
    /*right: -10px;*/
    left: -10px;
    content: "";
    background-color: #fff;
    border-radius: 10px;
    transform: perspective(6px) scale(1) rotateX(4deg);
    transform-origin: top left;
    z-index: 0;
  }

  .ft {
    position: relative;
    font-size: 18px;
    font-weight: bold;
    color: #01B0B0;
    padding-left: 7px;
    /*top: 7px;*/
  }
}

.sku-item {
  flex: 1;
  position: relative;
  width: 100%;
  margin: -2px 0 0;
  padding: 30px 2px 2px;
  box-sizing: border-box;
  border-radius: 0 10px 10px 10px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 6px 20px 0px rgba(235, 235, 235, 0.86);

  .scroll_show {
    overflow: auto;
    position: relative;
    height: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    /*max-height: 320px;*/
    overflow-y: auto;
  }

  .item-row:hover:after {
    /*border: 2px solid #ffffff !important;*/
  }

  .item-row::after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid #999999;
    border-radius: 10px;
    position: absolute;
    box-sizing: border-box;
    top: 17px;
    left: 6px;
  }

  .item-row {
    position: relative;
    display: flex;
    height: 46px;
    line-height: 46px;
    padding: 0 0 0 24px;
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      border-bottom: none;
    }

    .name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .num {
      flex: none;
      min-width: 38px;
      text-align: right;
    }

    /*span:nth-child(1) {
      position: absolute;
      left: 37px;
    }

    span:nth-child(2) {
      position: absolute;
      right: 26px;
    }*/
    &:last-child {
      &::before {
        display: none;
      }
    }
  }
}

@media screen and (max-width:1600px) {
  .sku-title {
    font-size: 16px;
  }
}


@media screen and (max-width:1400px) {
  .sku-title {
    font-size: 14px;
    border-radius: 10px 10px 0 0;
    background: #fff;
  }

  .sku-item {
    border-radius: 0 0 10px 10px;
  }
}

